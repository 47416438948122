import { useState } from "react";
import Styles from "./styles";

function MainCenter() {
  const [hover, setHover] = useState(null);
  const centerList = [
    {
      title: "성범죄",
      link: "https://onganglaw.co.kr/성범죄",
    },
    {
      title: "마약",
      link: "https://ongangdrug.co.kr/",
    },
    {
      title: "음주운전",
      // link: "http://drivecenter.co.kr/",
    },
    {
      title: "외국인",
    },
    {
      title: "군형사",
    },
    {
      title: "경제범죄",
    },
  ];

  const handleMouseOver = (index) => {
    setHover(index);
  };

  const handleMouseLeave = () => {
    setHover(null);
  };

  return (
    <Styles.MainCenter>
      <div className="center_inner">
        <h2 className="tit">
          법무법인 온강의 <strong>분야별 홈페이지</strong>
        </h2>
        <ul className={`center_list hover${hover}`}>
          {centerList.map((value, index) => {
            return (
              <li
                key={"centerList" + index}
                onClick={() => {
                  if (value.link) {
                    window.open(value.link);
                  } else {
                    alert("사이트 준비중입니다.");
                  }
                }}
                onMouseOver={() => handleMouseOver(index)}
                onMouseLeave={handleMouseLeave}
              >
                {value.title}
              </li>
            );
          })}
        </ul>
      </div>
    </Styles.MainCenter>
  );
}

export default MainCenter;
