import { useState, useEffect } from "react";
import Styles from "./styles.jsx";
import { useLocation } from "react-router-dom";

// img
import IconFooter from "resources/image/icon/icon_footer-logo.svg";
import IconKakao from "resources/image/icon/icon_kakaos.svg";
import IconYoutube from "resources/image/icon/icon_youtube_wh.svg";
import IconNaver from "resources/image/icon/icon_naver.svg";
import IconInsta from "resources/image/icon/icon_insta.svg";

// aside img
import IconYoutubeWhite from "resources/image/icon/icon_youtube_white.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top_white.svg";

import IconDrawer from "resources/image/icon/icon_drawer.svg";
import IconCloseGray from "resources/image/icon/icon_close-gray.svg";

const CustomSelect = () => {
  const [isClick, setIsClick] = useState(false);

  const onClickSelect = () => {
    setIsClick((val) => !val);
  };

  return (
    <div className="select-box">
      <div className="select-box__inner">
        <p onClick={onClickSelect} className="title">
          Family site
          <span className="img-box">
            <img src={isClick ? IconCloseGray : IconDrawer} alt="drawer" />
          </span>
        </p>
        {isClick && (
          <ul className="select-list">
            <li className="select-item">
              <a
                className="link"
                href={"https://onganglaw.co.kr"}
                target="_blank"
              >
                법무법인 온강 형사
              </a>
              <a
                className="link"
                href={"https://onganglaw.co.kr/성범죄"}
                target="_blank"
              >
                법무법인 온강 성범죄
              </a>
              <a
                className="link"
                href={"https://ongangdrug.co.kr/"}
                target="_blank"
              >
                법무법인 온강 마약
              </a>
              {/* <a
                className="link"
                href={"http://drivecenter.co.kr/"}
                target="_blank"
              >
                법무법인 온강 음주교통
              </a> */}
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

const AsideBar = ({ scroll }) => {
  const asideItem = [
    {
      class: "youtube",
      text: "인생변호사",
      src: IconYoutubeWhite,
      onclick: () => {
        window.open(
          "https://www.youtube.com/@mylifelawyer/playlists",
          "_blank"
        );
      },
    },
    {
      class: "topButton",
      text: "TOP",
      src: IconArrowTop,
      onclick: () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    },
  ];

  return (
    <aside className={"aside" + (scroll ? " active" : "")}>
      <ul className="aside-list">
        {asideItem.map((item, idx) => {
          return (
            <li className="item" key={idx}>
              <button
                type="button"
                className={item.class}
                onClick={item.onclick}
              >
                <img src={item.src} alt="asideIcon" />
                <span>{item.text}</span>
              </button>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

const FooterSnsList = [
  { id: 1, imgSrc: IconKakao, linkTo: "https://pf.kakao.com/_UHiqxj" },
  {
    id: 2,
    imgSrc: IconYoutube,
    linkTo: "https://www.youtube.com/@mylifelawyer",
  },
  { id: 3, imgSrc: IconNaver, linkTo: "https://blog.naver.com/ongangdrug" },
  {
    id: 4,
    imgSrc: IconInsta,
    linkTo: "https://www.instagram.com/mylifelawyer",
  },
];

const onCall = () => {
  window.location.href = "tel:1670-5901";
};

export default function Footer({ scroll, isBottom }) {
  const [isMain, setIsMain] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setIsMain(
      window.location.pathname == "/" ||
        window.location.pathname == "" ||
        window.location.pathname == "/intro/special"
    );
  }, [location]);
  

  return (
    <Styles.Footer>
      <div className="footer-container" style={{ marginTop: isMain ? 0 : 150 }}>
        <div className="footer-inner">
          <ul className="inner-list">
            <li className="inner-list--item left">
              <div className="img-box footer-logo">
                <img src={IconFooter} alt="온강 푸터 로고" />
              </div>
              <ul className="text-list">
                <li className="text-list--item heading">법무법인 온강</li>
                <li className="text-list--item">
                  <span className="text">사업자등록번호 : 593-81-03100</span>
                  <span className="text">대표 : 배한진</span>
                  <span className="text">광고책임변호사 : 배한진</span>
                  <span className="text">
                    주소 : 서울 서초구 서초대로 254, 오퓨런스빌딩 3층 302호
                  </span>
                  <span className="text">Fax : 02-6915-8059</span>
                  <span className="text">이메일 : ongang@ongang.kr</span>
                  <span className="text">
                    Copyright. 법무법인 온강. all rights reserved.
                  </span>
                </li>
              </ul>
            </li>
            <li className="inner-list--item right">
              <ul className="info-list">
                <li className="info-list__item--heading">
                  <p className="heading__text">법무법인 온강</p>
                  <p className="heading__phone" onClick={onCall}>
                    1670-5901
                  </p>
                  <p className="heading__subtext">24h 긴급대응, 상담예약</p>
                </li>
                <li className="info-list__item--sns">
                  <ul className="sns-list">
                    {FooterSnsList.map((footerItem, footerIdx) => {
                      return (
                        <li className="sns-list--item" key={footerIdx}>
                          <a
                            href={footerItem.linkTo}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={footerItem.imgSrc} alt="" />
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li className="info-list__item--family-site">
                  <CustomSelect />
                </li>
              </ul>
            </li>
          </ul>
        </div>
        {!window.location.pathname.includes("counsel") && !isBottom &&  (
          <AsideBar scroll={scroll} />
        )}
      </div>
    </Styles.Footer>
  );
}
