import { IMG } from "constant";
import { useDeviceType } from "hooks/useMediaQuery";
import React from "react";

const Intro = () => {
  const deviceType = useDeviceType();

  return (
    <section className="section description full">
      <div className="intro-wrap">
        <div className="intro-top">
          <img className="member-img" src={IMG.MEMBER_TOTAL} alt="members" />
        </div>
        <div className="intro-bottom">
          <img className="brand-logo" src={IMG.BRAND_LOGO} alt="brand logo" />
          <div className="summary">
            <div className="text-wrap">
              <h3>
                우리 의뢰인에게는 <strong>한없이 따뜻하게</strong>
                <br className="pcBr" />
                상대편인 수사 기관에게는 <strong>누구보다 강인하게</strong>
              </h3>
              <h4>
                온강의 이름에는 의뢰인을 향한 온강만의
                <br className="mbBr" />
                &nbsp;신념과 철학이 담겨있습니다.
                <br className="mbBr" />
                <br className="pcBr" />
                법무법인 온강을 찾아오기까지
                <br className="mbBr" />
                &nbsp;
                <br className="pcBr" />
                얼마나 많은 고민과 용기가 필요했을지 감히
                <br className="mbBr" />
                &nbsp;상상할 수 조차 없습니다.
                <br className="pcBr" />
              </h4>

              <h4>
                저희 온강이 이것 하나만은 꼭 약속드릴 수 있습니다.
                <br className="mbBr" />
                <br className="pcBr" />
                저희를 믿고 맡겨주신 의뢰인께서 다시 일상으로
                <br className="mbBr" />
                &nbsp;돌아갈 수 있도록,
                <br className="pcBr" />
                저희가 가진 전문성과 진정성으로
                <br className="mbBr" />
                &nbsp;열과 성을 다해 끝까지 변호한다는 점을 약속드립니다.
              </h4>
              <h4>
                단 1%의 가능성만 있다면,
                <br className="mbBr" />
                &nbsp;온강은 절대 의뢰인의 사건을 포기하지 않습니다.
              </h4>
            </div>
            <img className="typo-img" src={deviceType === "pc" ? IMG.TYPO : IMG.MB_TYPO} alt="typo img 의뢰인의 믿음에 결과로 보답하는 로펌, 법무법인 온강 입니다" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
